import React, { useState } from 'react';

import { LabData } from '../../../../../types/types';
import { Licensing } from '../../../../../types/types';
import LicenseDisplayBasic from '../license-display/license-display-basic';
import LicenseDisplayWithChildren from '../license-display/license-display-with-children';
import LicenseError from '../license-error/license-error';
import LicenseExpirationDate from '../license-expiration-date/LicenseExpirationDate';
import LicenseFormSubmitButton from '../license-form-submit-button/license-form-submit-button';
import '../license-form.scss';
import LicenseKeyDisplay from '../license-key-display/LicenseKeyDisplay';

const DEFAULT_ERROR_MESSAGE =
    'An error has occured while trying to retrieve your license.';

interface PushbuttonLicenseProps {
    /** Extra information about the lab */
    labData: LabData;
    licensingIntegrationFn: Licensing.LicensingIntegrationFunction;
    /** Content controls */
    content: Licensing.LicenseIntegrationContent;
    useLicenseKeyDisplay?: boolean;
}

/**
 * A component for simple 'pushbutton' license requests,
 * where the user acquires their license after simply
 * pushing a button.
 */
const PushbuttonLicense = ({
    labData,
    licensingIntegrationFn,
    content,
    useLicenseKeyDisplay
}: PushbuttonLicenseProps) => {
    const [licenseResponse, setLicenseResponse] = useState<Licensing.V2.LicensingServiceResponse | null>(null);
    const [licenseExpiration, setLicenseExpiration] = useState<string | null>(null);
    const [licenseError, setLicenseError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionComplete, setSubmissionComplete] = useState(false);
    const { buttonText, bodyText, htmlBody, successText } = content;

    /**
     * Get a license by way of a provided function
     * `licensingIntegrationFn` prop.
     */
    const getLicense = async () => {
        try {
            setIsSubmitting(true);
            const licenseResponse = await licensingIntegrationFn(labData);
            if ('error' in licenseResponse && licenseResponse.error) { 
                if (licenseResponse.error instanceof Error) {
                    setLicenseError(licenseResponse.error.message);
                }
                if (typeof licenseResponse.error === 'string') {
                    setLicenseError(licenseResponse.error as string);
                }
            }
            if ('success' in licenseResponse && licenseResponse.success) {
                setLicenseResponse(licenseResponse as Licensing.V2.LicensingServiceResponse);

                if ('expirationDate' in licenseResponse) {
                    setLicenseExpiration(licenseResponse.expirationDate);
                }
            }
        } catch (e) {
            if (e instanceof Error) {
                setLicenseError(e.message || DEFAULT_ERROR_MESSAGE);
            }
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            setIsSubmitting(false);
            setSubmissionComplete(true);
        }
    };

    return (
        <section className="license-form">
            {bodyText && (
                <section>
                    <p>{bodyText}</p>
                </section>
            )}
            {htmlBody && (
                <section>
                    <div dangerouslySetInnerHTML={ { __html: htmlBody } } />
                </section>
            )}
            <LicenseFormSubmitButton
                onClick={ () => getLicense() }
                disabled={
                    !!isSubmitting ||
                    !!licenseError ||
                    !!licenseResponse ||
                    submissionComplete
                }
                submitting={ isSubmitting }
            >
                { buttonText }
            </LicenseFormSubmitButton>

            {/* Basic License Display */}
            {licenseResponse?.licenseCode && !useLicenseKeyDisplay && (
                <LicenseDisplayBasic
                    license={ licenseResponse?.licenseCode }
                    licenseText={ successText }
                />
            )}

            {licenseResponse?.licenseCode && useLicenseKeyDisplay && (
                <LicenseKeyDisplay
                    licenseKey={ licenseResponse?.licenseCode }
                    decode={false}
                />
            )}

            {/* Special Data display w/ transformer */}
            {licenseResponse !== null && content.successTextTransformer && (
                <LicenseDisplayWithChildren>
                    {content.successTextTransformer(licenseResponse)}
                </LicenseDisplayWithChildren>
            )}
            {licenseExpiration && (
                <LicenseExpirationDate expirationDate={ licenseExpiration } />
            )}

            {licenseError && <LicenseError error={ licenseError } />}
        </section>
    );
};

export default PushbuttonLicense;
