import {OpenFeature} from '@openfeature/react-sdk';
import {
    setupFeatureFlags,
    getFlagMemoryStore,
    featureFlagDomain,
} from '../config/features/flags';
import DEFINED_FLAGS from '../config/features/flags/';

/**
 * Prepare feature flags for stories.
 *
 * This function will clear the feature flags, set up the feature flags with the defined flags,
 * define a client, and reduce the flag definitions to an object of flag names and their default values.
 * @summary Prepare feature flags for stories
 */
const prepareStoryFeatureFlags = async () => {
    // Next, set up the feature flags with the defined flags
    // We can ensure the provider is ready by setting the second argument to true
    await setupFeatureFlags(DEFINED_FLAGS, true);

    // Define a client, so we can pass it directly into the OpenFeatureProvider
    const client = OpenFeature.getClient(featureFlagDomain);

    // Next, reduce the flag definitions to an object of flag names and their default values
    const flagDefaults = Object.entries(getFlagMemoryStore()).reduce<
        Record<string, string>
    >((acc, [key, value]) => {
        acc[key] = value.configuration.defaultVariant;
        return acc;
    }, {});

    return {
        /**
         * The OpenFeature client instance set up during this process. This should be
         * passed directly into the OpenFeatureProvider in the story.
         * */
        client,
        /** Flag defaults extracted for story controls */
        flagDefaults,
    };
};

export default prepareStoryFeatureFlags;
