import { useState } from 'react';

import { LabData } from '../../../../../types/types';
import getHunchlyLicense from '../integration-functions/hunchly/hunchly';
import LicenseFormSubmitButton from '../license-form-submit-button/license-form-submit-button';
import LicenseKeyDisplay from '../license-key-display/LicenseKeyDisplay';
import LicenseError from '../license-error/license-error';

const Hunchly = ({labData}: {labData: LabData}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [requestSuccessful, setRequestSuccessful] = useState<boolean>(false);
    const [licenseError, setLicenseError] = useState<string | null>(null);
    const [license, setLicense] = useState<string | null>(null);

    /**
     * Submit a request for a Hunchly license. Upon success,
     * the license file blob is set in the state, triggering an
     * automatic download.
     */
    const submitLicenseRequest = async () => {
        setIsSubmitting(true);
        try{
            const licenseResponse = await getHunchlyLicense(labData);
            if (licenseResponse.error) {
                console.error(licenseResponse.error);
                setLicenseError(licenseResponse.error);
            } else {
                if ('success' in licenseResponse) {
                    setLicense(licenseResponse.licenseCode);
                }
            }
            setIsSubmitting(false);
            setRequestSuccessful(true);
        } catch (e){
            if (e instanceof Error) {
                setLicenseError(e.message);
                setIsSubmitting(false);
            }
        }
    };

    return (
        <section>
            <section className="license-field-row">
                <LicenseFormSubmitButton
                    disabled={ isSubmitting || requestSuccessful || !!licenseError }
                    submitting={ isSubmitting }
                    onClick={ submitLicenseRequest }
                >
                    Request Hunchly License
                </LicenseFormSubmitButton>
            </section>
            {
                licenseError && (
                    <section className="license-field-row">
                        <LicenseError error={licenseError} />
                    </section>
                )
            }
            {license && <LicenseKeyDisplay licenseKey={license} decode={true} />}
        </section>
    );
};

export default Hunchly;
