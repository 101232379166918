import {
    getArsenalLicense,
    getMaltegoLicense,
    getShodanLicense,
    getChainalysisLicense,
} from '../../utils/api-licensing';
import { getBurpProLicense } from './integration-functions/burpPro/burpPro';
import CloudProviders from '../cloud-providers/cloud-providers';
import CellebriteForm from './cellebrite/cellebrite';
import Corellium from './corellium/corellium';
import IdaPro from './ida/idapro';
import PushbuttonLicense from './pushbutton-license/PushbuttonLicense';
import { Licensing } from '../../../../types/types';
import getChatGPTLogin from './integration-functions/chat-gpt/chat-gpt';
import SuccessTextTransformer from './successTextTransformers/successTextTransformer';
import getHunchlyLicense from './integration-functions/hunchly/hunchly';
import Hunchly from './hunchly/Hunchly';

/**
 * An object mapping product licenses to their relevant forms
 */
const licenseForms: Licensing.LicenseMap = {
    'Cellebrite Tools': {component: CellebriteForm},
    'IDA Pro': {component: IdaPro},
    'IDA Pro Tools': {component: IdaPro},
    'Cloud Training Environment': {component: CloudProviders},
    'Corellium Platform Access': {component: Corellium},
    'Arsenal Tools': {
        component: PushbuttonLicense,
        licensingIntegrationFn: getArsenalLicense,
        content: {
            buttonText: 'Get Arsenal License',
        },
    },
    'Shodan Tools': {
        component: PushbuttonLicense,
        licensingIntegrationFn: getShodanLicense,
        content: {
            buttonText: 'Get Shodan License',
        },
    },

    'Maltego Tools': {
        component: PushbuttonLicense,
        licensingIntegrationFn: getMaltegoLicense,
        content: {
            buttonText: 'Request Maltego License',
            successText: 'Your gift code is ',
        },
    },
    'Chainalysis Platform Access': {
        component: PushbuttonLicense,
        licensingIntegrationFn: getChainalysisLicense,
        content: {
            buttonText: 'Request Chainalysis Credentials',
            successTextTransformer: SuccessTextTransformer,
        },
    },
    'OpenAI Tools - ChatGPT': {
        component: PushbuttonLicense,
        licensingIntegrationFn: getChatGPTLogin,
        content: {
            buttonText: 'Request ChatGPT Login',
            successTextTransformer: SuccessTextTransformer
        }
    },
    'Burp Pro Tools': {
        component: PushbuttonLicense,
        licensingIntegrationFn: getBurpProLicense,
        content: {
            buttonText: 'Request Burp Pro License',
            successText: 'Your license key is ',
        },
        display: {
            useLicenseKeyDisplay: true
        }
    },
    'Hunchly Tools': {
        component: Hunchly,
        licensingIntegrationFn: getHunchlyLicense,
        content: {
            buttonText: 'Request Hunchly License',
            successText: 'Your license key is ',
        }
    }
};

// If we need to exclude existing license fields (except AMI), add them to the
// the array below as they appear above, ie:
// export const licensingExclude = ['Cellebrite Tools'];
export const licensingExclude = [];

export default licenseForms;
