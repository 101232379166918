import { OpenFeature } from '@openfeature/react-sdk';

import { featureFlagDomain } from '../../../config/features/flags';
import { mylabsService, sansLicensingService } from '../../../serviceConnectors/serviceConnectors';
import { LabData, Licensing } from '../../../types/types';

interface CourseMetaData {
    label: string;
    name: string;
    key: number;
    course: string;
}

/**
 * Executes a POST request to the Sans Licensing service, with an optional payload.
 * On a successful response, `response.data.license` is returned, for easier use
 * with client/API integrations. If `response.data` is the preferred return,
 * set the `returnData` parameter
 */
export const fetchLicenseDataAsPost = async (
    licenseIntegration: string,
    labData: LabData,
    payload?: {[key: string]: string}
) => {

    const {modality, eventProductId, courseCatalogName} = labData;
    const courseMetadata: CourseMetaData = {
        /**
         * The run_id when it exists. Current fallbacks:
         * event_product_id > name
         */
        label: 'RUN_ID',
        /**
         * The modality, course catalog name, and run id of a lab's course
         */
        name: `${modality} ${courseCatalogName} (${eventProductId})`,
        // TODO: Document this field
        key: eventProductId,
        course: courseCatalogName,
    };

    const fullPayload = {...payload, metadata: courseMetadata};

    const response = await sansLicensingService.post(
        licenseIntegration,
        fullPayload,
        {
            responseType: 'json',
            transformResponse: [function (data){
                // Transform the response to return the same as the proxied response version
                const jsonData = JSON.parse(data);

                const responseData: Licensing.V2.LicensingServiceResponse = {
                    success: true,
                    error: null,
                    licenseData: null,
                    licenseCode: null,
                    expirationDate: null,
                    integration: licenseIntegration
                };
                
                if (jsonData) {
                    switch (licenseIntegration) {
                        case 'arsenal':
                        case 'shodan':
                        case 'maltego':
                        case 'burp-pro':
                            responseData.licenseCode = jsonData.license.license_code;
                            responseData.expirationDate = jsonData.license.expiration_date || null;
                            break;
                        
                        case 'cellebrite':
                            responseData.licenseData = {v2c: jsonData.license};
                            break;
                        
                        case 'chainalysis':
                            responseData.licenseData = jsonData.license.reactor_credentials;
                            responseData.expirationDate = jsonData.license.expiration_date || null;
                            break;
                        case 'corellium':
                            responseData.licenseData = jsonData.data;
                            break;
                        case 'idapro':
                            break;
                        default:
                            break;
                    }
                }
                return responseData;
            }]
        }
    );

    if (response.status >= 400) {
        throw new Error('An error has occurred.');
    }

    return response.data;

};

export const fetchLicenseDataAsPostv2 = async (
    license: string,
    labData: LabData,
    payload?: {[key: string]: string}
): Promise<Licensing.V2.LicensingServiceResponse> => {
    const {modality, eventProductId, courseCatalogName} = labData;
    const fullPayload = {
        payload: {
            ...payload,
        },
        license,
        eventProductId,
        courseCatalogName,
        modality,
    };
    const response = await mylabsService.post<Licensing.V2.LicensingServiceResponse>(
        'licensing',
        fullPayload,
    );

    if (response.status >= 400) {
        throw new Error('An error has occurred.');
    }

    return response.data;

};

export interface LicenseCodeResponse {
    license: string;
}

export interface LicensingParamsBase {
    labData: LabData;
}

const invokeFnByBoolFlag = (
    { license, labData, c2v, studentSelection, imageRemoval, projectRemoval }: { license: string, labData: LabData, c2v?: string, studentSelection?: string, imageRemoval?: string, projectRemoval?: string },
) => {
    const useProxy = OpenFeature.getClient(
        featureFlagDomain
    ).getBooleanValue('licensing-api-proxy', false);
    const ignoreLicensingProxy = localStorage.getItem('ignoreLicensingProxy');
    if (useProxy && ignoreLicensingProxy !== '1') {
        if (c2v) {
            return fetchLicenseDataAsPostv2(license, labData, { c2v });
        } else if (studentSelection) {
            return fetchLicenseDataAsPostv2(license, labData, { platform: studentSelection });
        } else if (imageRemoval && projectRemoval) {
            return fetchLicenseDataAsPostv2(license, labData, { imageRemoval, projectRemoval });
        } else {
            return fetchLicenseDataAsPostv2(license, labData);
        }
    } else {
        if (c2v) {
            return fetchLicenseDataAsPost(license, labData, {c2v});
        } else if (studentSelection) {
            return fetchLicenseDataAsPost(license, labData, { platform: studentSelection });
        } else if (imageRemoval && projectRemoval) {
            return fetchLicenseDataAsPost(license, labData, { imageRemoval, projectRemoval });
        } else {
            return fetchLicenseDataAsPost(license, labData);
        }
    }
};

export const getArsenalLicense = async (labData: LabData): Promise<
Licensing.V2.LicensingServiceResponse
> => {
    return invokeFnByBoolFlag({license: 'arsenal', labData});

};

export const getCellebriteLicense = async (
    labData: LabData,
    {c2v}: {c2v: string}
): Promise<
Licensing.V2.LicensingServiceResponse | {error: string}
> => {
    try {
        return await invokeFnByBoolFlag({license: 'cellebrite', labData, c2v});
    } catch (e) {
        let message;
        if (e instanceof Error) {
            message = e.message;
        }
        return {error: message || 'An error has occurred.'};
    }
};

export const createCorelliumPayload = (labData: LabData) => {
    const imageExpiration = new Date();
    const userExpiration = new Date();

    if (labData.modality === 'OnDemand') {
        imageExpiration.setMonth(imageExpiration.getMonth() + 4);
        userExpiration.setMonth(userExpiration.getMonth() + 4);
    } else {
        imageExpiration.setMonth(imageExpiration.getMonth() + 1);
        userExpiration.setDate(userExpiration.getDate() + 14);
    }

    return {
        imageRemoval: imageExpiration.toISOString(),
        projectRemoval: userExpiration.toISOString(),
    };
};

export const getCorelliumLicense = (labData: LabData): Promise<
Licensing.V2.LicensingServiceResponse
> => {
    return invokeFnByBoolFlag({license: 'corellium', labData, ...createCorelliumPayload(labData)});
};

export const getShodanLicense = async (labData: LabData): Promise<
Licensing.V2.LicensingServiceResponse
> => {
    return invokeFnByBoolFlag({license: 'shodan', labData});
};

export const getIDAProLicense = async (
    studentSelection: string,
    labData: LabData
): Promise<
Licensing.V2.LicensingServiceResponse |
{ error: string }
> => {
    try {
        return await invokeFnByBoolFlag({license: 'idapro', labData, studentSelection});
    } catch (e) {
        let message;
        if (e instanceof Error) {
            message = e.message;
        }
        return {error: message ?? 'An error has occurred.'};
    }
};

export const getMaltegoLicense = async (labData: LabData): Promise<
Licensing.V2.LicensingServiceResponse
> => {
    return invokeFnByBoolFlag({license: 'maltego', labData});
};

export const requestCloudEnvironmentByProviderV1 = async (
    provider: string,
    course: string,
    labData: LabData
) => {
    await fetchLicenseDataAsPost(`course/${course}`, labData, {
        provider,
    });
    return true;
};

export const requestCloudEnvironmentByProviderV2 = async (
    provider: string,
    course: string,
    labData: LabData
) => {
    await fetchLicenseDataAsPostv2(`course/${course}`, labData, {provider});
    return true;
};

export const requestCloudEnvironmentByProvider = async (
    provider: string,
    course: string,
    labData: LabData
) => {
    try {
        const useProxy = OpenFeature.getClient(
            featureFlagDomain
        ).getBooleanValue('licensing-api-proxy', false);
        if (useProxy) {
            return requestCloudEnvironmentByProviderV2(
                provider,
                course,
                labData
            );
        }
        return requestCloudEnvironmentByProviderV1(provider, course, labData);
    } catch (e) {
        let message;
        if (e instanceof Error) {
            message = e.message;
        }
        return {error: message};
    }
};

export const getChainalysisLicense = async (labData: LabData): Promise<
Licensing.V2.LicensingServiceResponse
> => {
    return invokeFnByBoolFlag({license: 'chainalysis', labData});
};
