/**
 * This file contains exports for service connectors, which are axios instances 
 * that are pre-configured to connect to a specific service.
 * @module serviceConnectors
 * @todo: Add Files API service Connector
 */

import axios from 'axios';

import appConfig from '../config/appConfig';

/**
 * Service connector for the MyLabs Service.
 * @category Service Connector
 */
export const mylabsService = axios.create({
    baseURL: appConfig().services.mylabsService.baseUrl,
    withCredentials: true,
});

/**
 * Service connector for the Sans Licensing Service.
 * @category Service Connector
 */
export const sansLicensingService = axios.create({
    baseURL: `${appConfig().services.sansLicensingService.baseUrl}`,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
    validateStatus(status) {
        return status >= 200 && status < 600; // default
    },
});
