import jszip from 'jszip';

/**
 * Converts a base64-encoded zip file to a browser-downloadable File
 * @param {string} b64Str - The base65-ended zip file.
 * @param {string} outputName - The name (without extension) for the file. Defaults to 'download'.
 * @returns {Promise<File>} The file in a browser-friendly form
 */
export const b64ToZip = async (b64Str, outputName = 'download') => {
    // eslint-disable-next-line new-cap
    const jsz = new jszip();
    const zipFromB64 = await jsz.loadAsync(b64Str, { base64: true, createFolders: true });
    const zipBlob = await zipFromB64.generateAsync({ type: 'blob' });
    const zipFile = new File([zipBlob], `${outputName}.zip`);

    return zipFile;
};
