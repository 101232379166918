import '../license-form.scss';
import {useState} from 'react';

import {LabData} from '../../../../../types/types';
import {getCellebriteLicense} from '../../../utils/api-licensing';
import {b64ToZip} from '../../../utils/b64-to-zip';
import {convertFileStrToBase64} from '../../../utils/utilities-licensing';
import LicenseDownload from '../license-download/license-download';
import LicenseError from '../license-error/license-error';
import LicenseFileUpload from '../license-file-upload/license-file-upload';
import LicenseFormSubmitButton from '../license-form-submit-button/license-form-submit-button';

interface CellebriteProps {
    labData: LabData;
}
/**
 * Renders a form to obtain a Cellbrite License.
 *
 * Utilizes <FileDownloader /> to instantly download the license
 * if the request is valid.
 */
const CellebriteForm = ({labData}: CellebriteProps) => {
    const [licenseError, setLicenseError] = useState<string | null>(null);
    const [licenseFileBlob, setLicenseFileBlob] = useState<File | null>(null);
    const [c2vBase64, setC2vBase64] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [requestSuccessful, setRequestSuccessful] = useState(false);

    /**
     * Converts a .c2v file to a base64 string and sets
     * it in the state.
     */
    const handleFile = async (uploadFile: File|FileList) => {
        try {
            if (uploadFile instanceof FileList) {
                setLicenseError('Please upload only one file');
                return null;
            }
            const c2vBase64Str = await convertFileStrToBase64(uploadFile);

            setC2vBase64(c2vBase64Str);
            return c2vBase64Str;
        } catch (e) {
            setLicenseError(
                'There was an error processing the provided .c2v file'
            );
            return null;
        }
    };

    /**
     * Submits a request for a Cellebrite license. If successful,
     * the license file blob is set in the state, triggering an
     * automatic download.
     */
    const submitLicenseRequest = async () => {
        setIsSubmitting(true);
        setLicenseError(null);
        try {
            if (c2vBase64) {
                const cellebriteLicense = await getCellebriteLicense(labData, {
                    c2v: c2vBase64,
                });
                if (
                    'success' in cellebriteLicense &&
                    'licenseData' in cellebriteLicense
                ) {
                    if (cellebriteLicense.licenseData && 'v2c' in cellebriteLicense.licenseData && cellebriteLicense.licenseData.v2c) {
                        const zipBlob = await b64ToZip(
                            cellebriteLicense.licenseData.v2c,
                            'license'
                        );
                        setLicenseFileBlob(zipBlob);
                    }
                } 
                setRequestSuccessful(true);
            } else {
                // No c2v file provided or string is empty
                setLicenseError('No .c2v file provided');
            }
        } catch (e) {
            if (e instanceof Error) {
                setLicenseError(`Error acquiring license. ${e.message}`);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <section className="license-form">
            <div className="license-field-row">
                <div className="license-form-field">
                    <LicenseFileUpload
                        inputLabel="Upload your .c2v file"
                        inputAccepts=".c2v"
                        cb={ handleFile }
                        showAllowedTypes={ true }
                        name="cellebrite-upload"
                    />
                </div>
            </div>
            <div className="license-field-row">
                <LicenseFormSubmitButton
                    disabled={ isSubmitting || !c2vBase64 || requestSuccessful }
                    submitting={ isSubmitting }
                    onClick={ submitLicenseRequest }
                >
                    Request License
                </LicenseFormSubmitButton>
            </div>
            { licenseError && <LicenseError error={ licenseError } />}
            { licenseFileBlob && (
                <LicenseDownload licenseFileBlob={ licenseFileBlob } />
            )}
        </section>
    );
};

export default CellebriteForm;
