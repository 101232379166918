import { http, bypass, HttpResponse } from 'msw';
import { store } from '../../index';
import { formatInstructionsAsRaw } from '../labs/data';
import { sec599V2FilesResponse } from '../../stories/mocks/sec599.stories';
import { sec560V2FilesResponse } from '../../stories/mocks/sec560.stories';
import AppConfiguration from '../../config/appConfig';
import { generatedLabMocks } from '../labs/courses/generateLabMocks';
import { FilesAPIRequestPayload } from '../../submodules/shared/utils/mylabs-files-api';

const config = AppConfiguration();
const { baseUrl } = config.services.mylabsService;

// Get data for mocked courses

// @ts-ignore
const { sec560: sec560Mocks, sec599: sec599Mocks } = generatedLabMocks;

// Set up our MSW mock handlers
// Since these handlers are used to augment real user data, we need to patch
// the original response with our mock data.
export default [
    http.get(`${baseUrl}/account_details`, async ({request}) => {
        // This handler only passes the original response through without modification,
        // but serves as an example of how to intercept responses.
        const originalResponse = await fetch(bypass(request)).then(response =>
            response.json()
        );

        return HttpResponse.json(
            originalResponse
        );
    }),
    http.get(`${baseUrl}/users`, async ({request}) => {
        const originalResponse = await fetch(bypass(request)).then(response =>
            response.json()
        );

        // copy the original response data to a new object
        const patchedResponse = { ...originalResponse };

        // Check the user's identity.
        // This method should be deprecated in favor of using feature flags
        // along with https://sans-enterprise.atlassian.net/browse/XHYD-579
        const user = store.getState().accountReducer.sansEmail||'NULL_EMAIL';
        const limitedTesters = process.env.REACT_APP_MYLABS_FEATURE_TEST || '';
        const limitedTestersArray = limitedTesters.split(',');
        const fullTesters = process.env.REACT_APP_MYLABS_FEATURE_TEST_DEV || '';
        const fullTestersArray = fullTesters.split(',');
        const allTesters = limitedTestersArray.concat(fullTestersArray);
        if (!allTesters.includes(user.split('@')[0]!)
        ) {
            return HttpResponse.json(
                originalResponse
            );
        }

        // Here, we extract our course mocks with more descriptive names and append them to the response
        // `formatInstructionsAsRaw` converts them to the format expected by the UI
        const { a: sec560A, b: sec560B } = sec560Mocks.live[80755];
        const { a: sec599 } = sec599Mocks.live[79170];

        patchedResponse.data = patchedResponse.data.concat([
            formatInstructionsAsRaw(sec560A), formatInstructionsAsRaw(sec560B),
            formatInstructionsAsRaw(sec599),
        ]);

        return HttpResponse.json(
            patchedResponse
        );
    }),
    http.post('http://localhost:8200/api/files', async ({request}) => {
        const originalResponse = await fetch(bypass(request)).then(response =>
            response.json()
        );

        const user = store.getState().accountReducer.sansEmail||'NULL_EMAIL';
        const limitedTesters = process.env.REACT_APP_MYLABS_FEATURE_TEST || '';
        const limitedTestersArray = limitedTesters.split(',');
        const fullTesters = process.env.REACT_APP_MYLABS_FEATURE_TEST_DEV || '';
        const fullTestersArray = fullTesters.split(',');
        const allTesters = limitedTestersArray.concat(fullTestersArray);
        if (!allTesters
            .includes(user.split('@')[0]!)
        ) {
            return HttpResponse.json(
                originalResponse
            );
        }

        // NOTE: Technically, we're using feature flags for this, but v1 is completely deprecated
        const reqPayload = await request.json() as FilesAPIRequestPayload;
        const sec599Files = reqPayload[sec599Mocks.live[79170].a.testExtras.courseCatalog];
        const sec560Files = reqPayload[sec560Mocks.live[80755].a.testExtras.courseCatalog];
        const patchedFileData = {
            ...sec599V2FilesResponse(
                sec599Files ? sec599Files[0] : null
            ),
            ...sec560V2FilesResponse(
                sec560Files ? sec560Files[0] : null
            ),
        };

        return HttpResponse.json(
            { ...originalResponse, ...patchedFileData }
        );
    }),
];


