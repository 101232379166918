import CloudProviderButton from './cloud-provider-button';
import AppConfig from '../../../../config/appConfig';
import { LabData } from '../../../../types/types';

const config = AppConfig();

const DEFAULT_CLOUD_PROVIDERS = config.integrations.cloudProviders.providers.default;

interface CloudProvidersProps {
    /** Abbreviated data from `lab` object */
    labData: Partial<LabData>;
    /** A string list of cloud providers */
    providers: string[];
    /**  The body text of the CloudProvider's section */
    text: string;
}

/**
 * Default body text for the CloudProviders component
 */
const DEFAULT_BODY = 'Select your cloud provider from the options below.';

const defaultProps: CloudProvidersProps = {
    providers: DEFAULT_CLOUD_PROVIDERS,
    text: DEFAULT_BODY,
    labData: {},
};

/**
 * Container component for cloud provider buttons
 * @component
 */
const CloudProviders = (props: CloudProvidersProps) => {
    const { providers } = props;

    return (
        <section>
            <header>Cloud Providers</header>
            <p>{props.text}</p>
            <section className="flex column w-12">
                {providers.map((provider) => (
                    <CloudProviderButton
                        key={ `cloudProvider-${provider}` }
                        provider={ provider }
                        labData={ props.labData }
                    />
                ))}
            </section>
        </section>
    );
};

CloudProviders.defaultProps = defaultProps;

export default CloudProviders;
