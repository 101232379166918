import { setupStore } from '../../../../app/store';
import LabDisplay from './lab-display';
import { Provider } from 'react-redux';
import React from 'react';
import { TestConfigState } from '../../../../mocks/redux/data';
import { TestLab, formatInstructionsAsRaw } from '../../../../mocks/labs/data';
import { OpenFeatureProvider } from '@openfeature/react-sdk';
import { featureFlagDomain } from '../../../../config/features/flags';
import { testUserResponse, testAccountResponse } from '../../../../mocks/myLabsTestData';

import type { Meta, StoryObj } from '@storybook/react';
import { http, HttpResponse } from 'msw';
import appConfig from '../../../../config/appConfig';
export const MockedState = {
    accountReducer: testAccountResponse.data,
    configReducer: {
        config: TestConfigState(),
    },
    labReducer: {
        labResults: testUserResponse.data,
        isLoading: false,
        error: null,
    },
    filesReducer: {
        files: {},
    },
    rangeProvisioningReducer: {
        courses: testUserResponse.rangeProvisioning.courses.data,
        ranges: testUserResponse.rangeProvisioning.ranges.data,
    },
};

/**
 * @deprecated - This should be removed when all references to this are removed from other modules.
 * All lab data should come from testUserResponse.data
 */
export const mockedLabs = {
    singleGeneric: [formatInstructionsAsRaw(TestLab({
        id: 1,
        productId: 1,
    }))],
    onDemandGeneric: [
        formatInstructionsAsRaw(TestLab({ course: 'CSE101', id: 2, productId: 2, nameSectionSuffix: 'A' })),
        formatInstructionsAsRaw(TestLab({
            course: 'CSE101',
            id: 3,
            productId: 2,
            nameSectionSuffix: 'B',
            instructions: {},
            additionalInstructions: {
                Overview: [
                    {
                        field_lab_instruction_display_na:
                            'Test Lab B Overview',
                        field_lab_instruction: '<p>This is a test lab</p>',
                    },
                ],
            },
        })),
    ],
    liveGeneric: [
        formatInstructionsAsRaw(TestLab({
            course: 'CSE102',
            id: 4,
            productId: 3,
            eventProductId: 123,
            nameSectionSuffix: 'A',
            eventName: 'Automated Live Test Lab',
            modality: 'LIVE',
        })),
        formatInstructionsAsRaw(TestLab({
            course: 'CSE102',
            id: 5,
            productId: 3,
            eventProductId: 123,
            section: '6',
            nameSectionSuffix: 'B',
            eventName: 'Automated Live Test Lab',
            modality: 'LIVE',
        })),
    ],
};


const meta: Meta<typeof LabDisplay> = {
    title: 'Layout/Lab Display',
    component: LabDisplay,
    decorators: [(Story) =>
        <OpenFeatureProvider domain={ featureFlagDomain }>
            {/* @ts-ignore */}
            <Provider store={ setupStore(MockedState) } >
                <Story />
            </Provider>
        </OpenFeatureProvider>,
    ],
    parameters: {
        msw: {
            handlers: [
                http.get('https://www.sans.org/services/pywars/accountcreate', () => {
                    return HttpResponse.json({password: 'pywarspassword'});
                }),
                http.get('https://www.sans.org/services/pywars/passwordreset', () => {
                    return HttpResponse.json({password: 'newpassword'});
                }),
                http.get(appConfig().services.skillable.baseUrl, () => {
                    return HttpResponse.json({url: 'https://test.example.com'});
                }),
                
            ],
        },
    },
    excludeStories: ['MockedState', 'mockedLabs'],
};

export default meta;
type Story = StoryObj<typeof LabDisplay>;

export const Default: Story = {
    args: {
        labResults: testUserResponse.data,
    },
    parameters: {
        msw: {
            handlers: [
                // dynamic ovpn mock
                // eslint-disable-next-line
                http.get(`${appConfig().services.mylabsService.baseUrl}/ovpn/:labId/:userProductCacheId?os=foo`, ({ params }) => {
                    const { labId } = params;
                    const file = new Blob([`foo ${labId}`], { type: 'text/plain' });
                    return HttpResponse.json(
                        { filename: 'foo.ovpn', file },
                        {
                            headers: {
                                'Content-Type': 'text/plain',
                                'Content-Length': file.size.toString(),
                            },
                        }
                    );
                }),
            ],
        },
    },
};

const WithFilesState = {
    ...MockedState,
    filesReducer: {
        files: {
            'CSE100-I01-GEN-null': {
                metadata: [
                    {
                        filename: 'foo.txt',
                        id: 'abc123',
                        description: '',
                        'content-type': 'text/plain',
                        'content-disposition': 'attachment',
                        'content-length': 10,
                        scope: 'student',
                        courseID: 'cse100',
                        instanceID: 'cse100-i01-gen',
                        studentID: 123456,
                        token: 'acbd1234'
                    },
                ],
                eventProductID: null,
                labID: 100,
                labName: 'CSE100-I01-GEN',
                instance: 'CSE100-I01-GEN',
            }
        },
    },
}

export const WithFiles: Story = {
    args: {
        labResults: testUserResponse.data,
    },
    decorators: [(Story) => 
        <OpenFeatureProvider domain={ featureFlagDomain }>
            {/* @ts-ignore */}
            <Provider store={ setupStore(WithFilesState) } >
                <Story labResults={testUserResponse.data} />
            </Provider>
        </OpenFeatureProvider>
        ,
    ],
    parameters: {
        msw: {
            handlers: [
                // dynamic ovpn mock
                // eslint-disable-next-line
                http.get(`${appConfig().services.mylabsService.baseUrl}/ovpn/:labId/:userProductCacheId?os=foo`, ({ params }) => {
                    const { labId } = params;
                    const file = new Blob([`foo ${labId}`], { type: 'text/plain' });
                    return HttpResponse.json(
                        { filename: 'foo.ovpn', file },
                        {
                            headers: {
                                'Content-Type': 'text/plain',
                                'Content-Length': file.size.toString(),
                            },
                        }
                    );
                }),
            ],
        },
    },
};

/**
 * All requests in this lab are set to return invalid responses
 */
export const WithErrors: Story = {
    args: {
        labResults: testUserResponse.data,
    },
    parameters: {
        msw: {
            handlers: [
                http.get('https://www.sans.org/services/pywars/accountcreate', () => {
                    return HttpResponse.json(
                        {error_Status: 2}
                    );
                }),
                http.get('https://www.sans.org/services/pywars/passwordreset', () => {
                    return HttpResponse.json(
                        {error: 'pywarspassword'}
                    );
                }),
                http.get(appConfig().services.skillable.baseUrl, () => {
                    return HttpResponse.json(
                        {foo: 'https://test.example.com'}
                    );
                }),
                 
            ],
        },
    },
};

export const NoLabs: Story = {
    args: {
        labResults: [],
    },
};
