import { LabData, Licensing } from '../../../../../../types/types';
import { fetchLicenseDataAsPostv2 } from '../../../../utils/api-licensing';

/**
 * Make a request to MyLabs Service to get a chatgpt login.
 * @param labData 
 */
const getChatGPTLogin = async (labData: LabData): Promise<Licensing.V2.LicensingServiceResponse> => {
    return await fetchLicenseDataAsPostv2('openai/chatgpt', labData);
};

export default getChatGPTLogin;
