import transformChainalysisLicense, { ChainalysisLicenseData } from './chainalysisSuccessTextTransformer';
import chatGPTSuccessTextTransformer from './chatgptSuccessTextTransformer';
import { Licensing } from '../../../../../types/types';

const SuccessTextTransformer = (
    licenseResponse: Licensing.V2.LicensingServiceResponse
) => {
    if (licenseResponse.integration === 'openai/chatgpt') {
        return chatGPTSuccessTextTransformer(licenseResponse.licenseData as Licensing.ChatGPTLicenseData);
    }

    return transformChainalysisLicense(licenseResponse.licenseData as ChainalysisLicenseData);
};

export default SuccessTextTransformer;
