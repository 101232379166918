import { useState } from 'react';

import { LabData } from '../../../../../types/types';
import { getCorelliumLicense } from '../../../utils/api-licensing';
import LicenseDisplayWithChildren from '../license-display/license-display-with-children';
import LicenseError from '../license-error/license-error';
import LicenseFormSubmitButton from '../license-form-submit-button/license-form-submit-button';
import '../license-form.scss';

interface CorelliumProps {
    labData: LabData;
}

const Corellium = ({ labData }: CorelliumProps) => {
    const [registrationData, setRegistrationData] = useState<{
        email: string;
    }|null>(null);
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const [licenseError, setLicenseError] = useState<null|string>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onClickHandler = async () => {
        try {
            setIsSubmitting(true);
            const licenseResponse = await getCorelliumLicense(labData);

            if ('error' in licenseResponse && licenseResponse.error) { 
                throw new Error(licenseResponse.error);
            }

            const licenseData = licenseResponse.licenseData;

            if (licenseData && 'email_address' in licenseData && licenseData.email_address) {
                setRegistrationData({
                    email: licenseData.email_address,
                });
                setRequestSuccessful(true);
            }
        } catch (e) {
            if (e instanceof Error){
                setLicenseError(e.message);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <section>
            <LicenseFormSubmitButton
                onClick={ onClickHandler }
                disabled={ !!isSubmitting || !!requestSuccessful || !!licenseError }
                submitting={ isSubmitting }>
                Register for Corellium
            </LicenseFormSubmitButton>
            {requestSuccessful && (
                <LicenseDisplayWithChildren>
                    <p>
                        Our records indicate that you should be signed up on
                        the Corellium platform with the email address <b>{registrationData?.email}.</b>
                    </p>
                </LicenseDisplayWithChildren>
            )}

            {licenseError && <LicenseError error={ licenseError } />}
        </section>
    );
};

export default Corellium;
