import {
    API_BASE,
    API_CREDENTIALS,
} from './submodules/shared/constants-base';

const PATH = {
    base: '/',
    home: '/home',
    lab: '/lab',
    labAction: (id, action) => id ? `/lab/${id}/${action}` : '/lab/:id/:action',
};

const SORT_DIRECTIONS = {
    ASC: 'asc',
    DESC: 'desc',
};

const OVPN_PATH = 'ovpn';

const ACCOUNT_DETAILS_PATH = 'account_details';

const FAILED_API_NOTFICATION = {
    type: 'danger',
    message: `<p>You do not have access to any labs at this time. If you should have access,
    contact <a href="mailto:mylabs-support@sans.org">mylabs-support@sans.org</a>.</p>`,
};

export {
    API_BASE,
    API_CREDENTIALS,
    OVPN_PATH,
    PATH,
    SORT_DIRECTIONS,
    ACCOUNT_DETAILS_PATH,
    FAILED_API_NOTFICATION,
};
