import React, { useEffect, useState } from 'react';

import { LabData } from '../../../../../types/types';
import { getIDAProLicense } from '../../../utils/api-licensing';
import LicenseError from '../license-error/license-error';
import './idapro.scss';

const options = [
    'Windows',
    'Mac',
    'Linux',
    'Opt-Out (I will provide my own IDA 7.4+ license)',
];
type IdaProProps = {
    labData: LabData
};
const IdaPro = ({ labData }: IdaProProps) => {
    const [userAgent] = useState(window.navigator.userAgent);
    const resolveInitialPlatformOption = () => {
        // Map user agent to an option...
        if (userAgent.match(/Macintosh/) !== null) {
            return 'Mac';
        }
        if (userAgent.match(/Linux/) !== null) {
            return 'Linux';
        }

        // Default option...
        return 'Windows';
    };

    const [licenseRequested, setLicenseRequested] = useState(false);
    const [licenseError, setLicenseError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [platform, setPlatform] = useState(resolveInitialPlatformOption());

    useEffect(() => {
        resolveInitialPlatformOption();
    });

    const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setPlatform(e.target.value);
    };

    const getLicense = async () => {
        setIsSubmitting(true);
        setLicenseError('');

        let req;

        try {
            req = await getIDAProLicense(platform, labData);
        } catch (e) {
            // Handle errors from the integration function
            const errorMessage = `Failed requesting the IDA Pro license: ${e}`;
            setLicenseError(errorMessage);
        } finally {
            // Handle errors from the response
            if (req && 'error' in req && typeof req.error !== 'undefined' && req.error !== '' && req.error !== null) {
                setLicenseError('Please ensure you are connected to the Internet and try again.');
            } else {
                setLicenseRequested(true);
            }

            setIsSubmitting(false);
        }
    };

    const RequestLicenseButton = () => {
        return (
            <div>
                <label>
            Choose your platform: {' '}
                    <select
                        className="idapro-platform"
                        onChange={ handleSelect }
                        value={ platform }
                        disabled={ licenseRequested || isSubmitting }
                    >
                        {options.map((opt, i) => (
                            <option key={ i } value={ opt }>
                                {opt}
                            </option>
                        ))}
                    </select>
                </label>
                <button
                    className="idapro-licensing-submit-btn"
                    disabled={ licenseRequested || isSubmitting }
                    onClick={ () => getLicense() }
                >
            Register for IDA Pro
                </button>
            </div>
        );
    };

    const SuccessMessage = () => {
        return (
            <p className="idapro-success-message">
                {platform.includes('Opt-Out') && 'Thank you for registering. '}
                { !platform.includes('Opt-Out') && `Thank you for requesting your license for ${platform}. You will receive an email directly from Hex Rays with your license key. Please allow for up to 48 hours to hear from Hex Rays.` }
            </p>
        );
    };

    return (
        <section>
            { licenseError !== '' && <LicenseError error={ licenseError } altResourceName="IDA Pro license" /> }
            { isSubmitting && <div className="licensing-submit-btn__download-spinner" data-testid="download-spinner" /> }
            { licenseRequested && <SuccessMessage /> }
            { !licenseRequested && <RequestLicenseButton /> }
        </section>
    );
};

export default IdaPro;
