import './license-key-display.scss';
import '../license-form.scss';
import '../license-form-submit-button/license-form-submit-button.scss';
import { useEffect, useState } from 'react';
import LicenseFormSubmitButton from '../license-form-submit-button/license-form-submit-button';
type LicenseKeyDisplayProps = {
    /** b64 representation of the key */
    licenseKey: string,
    decode: boolean,
};

const LicenseKeyDisplay = ({
    licenseKey,
    decode
}: LicenseKeyDisplayProps) => {
    const [file, setFile] = useState<File | null>(null);
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [downloadDate, setDownloadDate] = useState<string | null>(null);

    let decoded: string|null = null;
    let user: string|null = null;
    let expiration: string|null = null;

    if (decode){
        decoded = atob(licenseKey);
        const match = decoded.match(/^(\d+),(\d+-\d+-\d+$)/mi);
        user = match?.[1]||null;
        expiration = match?.[2]||null;
    }
    
    useEffect(() => {
        // If decode is true, decode the license key, kicking off [decoded] useEffect
        const requestDate = new Date();
        const year = requestDate.getFullYear();
        const month = requestDate.getMonth();
        const day = requestDate.getDate();
        setDownloadDate(`${year}-${month}-${day}`);
        if (decoded){
            const file = new File([decoded], 'license.key', { type: 'text/plain' });
            setFile(file);
            setFileUrl(URL.createObjectURL(file)); 
        }
        return () => {
            if (fileUrl) {
                URL.revokeObjectURL(fileUrl);
            }
        };
    }, [licenseKey]);

    const copyLicenseKey = () => {
        navigator.clipboard.writeText(decoded||licenseKey);
    };
    return (
        <section style={{marginTop: '1rem'}}>
            <section>
                {user && <p><strong>User:</strong> {user}</p>}
                {expiration && <p><strong>Expires:</strong> {expiration}</p>}
            </section>
            <section>
                <pre className='license-key-pre'>
                    {decoded||licenseKey}
                </pre>
            </section>
            <section>
                <LicenseFormSubmitButton onClick={copyLicenseKey} style={{marginRight: '1rem'}}>Copy</LicenseFormSubmitButton>
                {
                    // at the moment, only hunchly licenses can be downloaded
                    fileUrl &&
                    <a href={fileUrl} download={`hunchlylicense_${downloadDate}.hlic`}>
                        <LicenseFormSubmitButton >Download</LicenseFormSubmitButton>
                    </a>
                }
            </section>
        </section>
    );
};

export default LicenseKeyDisplay;
