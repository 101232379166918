import './license-expiration-date.scss';

interface LicenseExpirationDateProps {
    /** The expiration date of the license */
    expirationDate: string;
}

const LicenseExpirationDate = (props: LicenseExpirationDateProps) => (
    <div>
        <p className="license-expiration-date">License expiration date: {props.expirationDate}</p>
    </div>
);

export default LicenseExpirationDate;
