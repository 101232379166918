import { LabData, Licensing } from '../../../../../../types/types';
import { fetchLicenseDataAsPostv2 } from '../../../../utils/api-licensing';

/**
 * Make a request to MyLabs Service to get a Hunchly license.
 * @param labData 
 */
const getHunchlyLicense = async (labData: LabData): Promise<Licensing.V2.LicensingServiceResponse> => {
    return await fetchLicenseDataAsPostv2('hunchly', labData);
};

export default getHunchlyLicense;
