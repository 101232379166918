import { RangeInstance, RangeRule } from '../../types/types';
import { TestLab, formatInstructionsAsRaw } from '../labs/data';
import { createRangeData } from '../ranges/data';

// Set up some time variables to use across labs
const today = new Date();

// Most labs should start five days ago
const labStartDate = new Date();
labStartDate.setDate(today.getDate() - 5);

/**
 * A formatted version of the start date as sent from the API
 * (eg, `"2023-04-01 18:35:25"`)
 */
const utcLabStartString = `${labStartDate.getFullYear()}-${(
    labStartDate.getMonth() + 1
)
    .toString()
    .padStart(2, '0')}-${labStartDate
    .getDate()
    .toString()
    .padStart(2, '0')} 06:00:00`;

/**
 * The date portion of the start date as sent from the API
 */
const labStartString = utcLabStartString.substring(0, 10); // eg, 2000-01-01

const onDemandLabExpirationDate = new Date();
onDemandLabExpirationDate.setMonth(labStartDate.getMonth() + 4);
onDemandLabExpirationDate.setDate(labStartDate.getDate());

const onDemandLabExpirationDateString = `${onDemandLabExpirationDate.getFullYear()}-${(
    onDemandLabExpirationDate.getMonth() + 1
)
    .toString()
    .padStart(2, '0')}-${onDemandLabExpirationDate
    .getDate()
    .toString()
    .padStart(2, '0')} 06:00:00`;

const liveLabExpirationDate = new Date();

liveLabExpirationDate.setDate(labStartDate.getDate() + 7);

const liveLabExpirationDateString = `${liveLabExpirationDate.getFullYear()}-${(
    liveLabExpirationDate.getMonth() + 1
)
    .toString()
    .padStart(2, '0')}-${liveLabExpirationDate
    .getDate()
    .toString()
    .padStart(2, '0')} 06:00:00`;

const lab2StartExceptionDate = new Date(labStartDate);
lab2StartExceptionDate.setDate(labStartDate.getDate() + 5);
const lab2StartExceptionDateString = `${lab2StartExceptionDate.getFullYear()}-${(
    lab2StartExceptionDate.getMonth() + 1
)
    .toString()
    .padStart(2, '0')}-${lab2StartExceptionDate
    .getDate()
    .toString()
    .padStart(2, '0')} 06:00:00`;

const overviewOnly = formatInstructionsAsRaw(
    TestLab({
        id: 100,
        course: 'CSE100',
        modality: 'GEN',
        version: 'I01',
        productId: 1000,
        courseName: 'Basic Lab',
        type: 'HTTP_AUTH',
        utcLabStart: utcLabStartString,
        labStart: labStartString,
        expirationDate: onDemandLabExpirationDateString,
    })
);

const lodsCloud = formatInstructionsAsRaw(
    TestLab({
        id: 101,
        course: 'CSE101',
        modality: 'GEN',
        version: 'I01',
        productId: 1001,
        courseName: 'LODS Cloud Lab',
        type: 'LODS_CLOUD',
        utcLabStart: utcLabStartString,
        labStart: labStartString,
        expirationDate: onDemandLabExpirationDateString,
    })
);

const licenseInstructions = [
    'Arsenal Tools',
    'Cellebrite Tools',
    'Corellium Platform Access',
    'IDA Pro',
    'IDA Pro Tools',
    'Maltego Tools',
    'Flashpoint Tools',
    'Shodan Tools',
    'Cloud Training Environment',
].map((tool) => ({
    field_lab_id: 102,
    title: `CSE102-I01-GEN`,
    field_instruction_type: 'Licensing',
    field_lab_instruction_display_na: tool,
    field_lab_instruction: '<p>This is a test lab</p>',
}));

const licensing = formatInstructionsAsRaw(
    TestLab({
        id: 102,
        course: 'CSE102',
        modality: 'GEN',
        version: 'I01',
        productId: 1002,
        courseName: 'Licensing Integrations Lab',
        type: 'HTTP_AUTH',
        additionalInstructions: {
            Licensing: [
                'Arsenal Tools',
                'Cellebrite Tools',
                'Corellium Platform Access',
                'IDA Pro',
                'IDA Pro Tools',
                'Maltego Tools',
                'Flashpoint Tools',
                'Shodan Tools',
                'Cloud Training Environment',
            ].map((tool) => ({
                field_lab_id: 102,
                title: `CSE102-I01-GEN`,
                field_instruction_type: 'Licensing',
                field_lab_instruction_display_na: tool,
                field_lab_instruction: '<p>This is a test lab</p>',
            })),
        },
        utcLabStart: utcLabStartString,
        labStart: labStartString,
        expirationDate: onDemandLabExpirationDateString,
    })
);

const liveA = formatInstructionsAsRaw(
    TestLab({
        id: 103,
        course: 'CSE103A',
        modality: 'LIVE',
        version: 'I01',
        productId: 1003,
        courseName: 'Live  (A/B)',
        type: 'HTTP_AUTH',
        eventName: 'Automated Live Test Lab',
        utcLabStart: utcLabStartString,
        labStart: labStartString,
        expirationDate: liveLabExpirationDateString,
    })
);

const liveB = formatInstructionsAsRaw(
    TestLab({
        id: 104,
        course: 'CSE103B',
        modality: 'LIVE',
        version: 'I01',
        productId: 1003,
        courseName: 'Live Lab',
        type: 'HTTP_AUTH',
        section: '6',
        eventName: 'Automated Live Test Lab',
        utcLabStart: utcLabStartString,
        labStart: labStartString,
        expirationDate: liveLabExpirationDateString,
        lab2StartException: lab2StartExceptionDateString,
    })
);

export const liveSingle = formatInstructionsAsRaw(
    TestLab({
        id: 105,
        course: 'CSE104',
        modality: 'LIVE',
        version: 'I01',
        productId: 1004,
        courseName: 'Live Lab (Single Section)',
        type: 'HTTP_AUTH',
        section: '1',
        utcLabStart: utcLabStartString,
        labStart: labStartString,
        expirationDate: liveLabExpirationDateString,
    })
);

const ovpnLinux = formatInstructionsAsRaw(
    TestLab({
        id: 106,
        course: 'CSE105',
        modality: 'GEN',
        version: 'I01',
        productId: 1005,
        courseName: 'Linux OVPN Lab',
        type: 'HTTP_AUTH',
        ovpnLinux: true,
        utcLabStart: utcLabStartString,
        labStart: labStartString,
        expirationDate: onDemandLabExpirationDateString,
    })
);
const ovpnWindows = formatInstructionsAsRaw(
    TestLab({
        id: 107,
        course: 'CSE105',
        modality: 'GEN',
        version: 'I01',
        productId: 1006,
        courseName: 'Windows OVPN Lab',
        type: 'HTTP_AUTH',
        ovpnWindows: true,
        utcLabStart: utcLabStartString,
        labStart: labStartString,
        expirationDate: onDemandLabExpirationDateString,
    })
);

const pywars = formatInstructionsAsRaw(
    TestLab({
        id: 108,
        course: 'CSE106',
        modality: 'GEN',
        version: 'I01',
        productId: 1007,
        courseName: 'PyWars Lab',
        type: 'PYWARS',
        utcLabStart: utcLabStartString,
        labStart: labStartString,
        expirationDate: onDemandLabExpirationDateString,
    })
);
const ranges = formatInstructionsAsRaw(
    TestLab({
        id: 109,
        course: 'CSE107',
        modality: 'GEN',
        version: 'I01',
        productId: 1008,
        courseName: 'Ranges Lab',
        type: 'HTTP_AUTH',
        utcLabStart: utcLabStartString,
        labStart: labStartString,
        expirationDate: onDemandLabExpirationDateString,
    })
);

const rangeRules: RangeRule[] = [
    {
        account_id: null,
        attributes: null,
        aws_regions: [],
        created_at: '2023-04-01 18:35:25',
        default_duration_in_days: 14,
        extension_duration_in_days: 14,
        id: 1,
        maximum_duration_in_days: 28,
        maximum_runs: 5,
        mylabs_part: 'cse107-i01-gen',
        product_version: 'cse107-i01-01',
        provisioning_duration_in_minutes: 5,
        range_type: 'labs-aws',
        updated_at: '2023-04-01 18:35:25',
    },
];

const rangeData: RangeInstance[] = [
    createRangeData({
        mylabsPart: 'cse107-i01-gen',
        accountId: 12345678,
        status: 'pending',
        createdAt: new Date().toISOString(),
        id: 1000,
    }),
];

const TEST_USER_RESPONSE = {
    result: 'success',
    rangeProvisioning: {
        courses: {
            data: rangeRules,
        },
        ranges: {
            data: [],
        },
    },
    data: [
        overviewOnly,
        lodsCloud,
        licensing,
        liveA,
        liveB,
        liveSingle,
        ovpnLinux,
        ovpnWindows,
        pywars,
        ranges,
    ],
};

export {TEST_USER_RESPONSE};
