import { Licensing } from '../../../../../types/types';

export const chatGPTSuccessTextTransformer = (licenseData: Licensing.ChatGPTLicenseData) => {
    return (
        <section style={{marginTop: '1em'}}>
            <span>OpenAI Login Address: </span>
            <a href={licenseData.auth_url}>{licenseData.auth_url}</a>
        </section>
    );
};

export default chatGPTSuccessTextTransformer;
