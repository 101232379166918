/**
 * Unified configuration data for Application modules and functions.
 * @module appConfig
 */

import config from './config.json';
import pkg from '../../package.json';

export type RuntimeEnvironment =
    | 'test'
    | 'local'
    | 'legacy-development'
    | 'legacy-production'
    | 'org7-development'
    | 'org7-production'
    | 'org7-qa'
    | 'org7-uat';

/** Configuration for Application Services */
export interface AppServiceConfiguration {
    /** The base url of the service (generally, the base service URL)*/
    baseUrl: string;
    /** Endpoints from the service available to the application */
    endpoints: {
        /** An endpoint for the service (`/some-endpoint`) */
        [index: string]: string;
    };
}

/** Application Configuration for services and integrations */
export interface AppConfiguration {
    /** The version of the application */
    version: string;
    /** Feature integrations */
    integrations: {
        /** Cloud Provider Features */
        cloudProviders: {
            /** Which providers are available? */
            providers: {
                default: string[];
            };
        };
    };
    /** External services the application can connect to (including SANS Services) */
    services: {
        /** MyLabs Service, the primary backend of the application */
        mylabsService: AppServiceConfiguration;
        /** PyWars Service, the primary backend of the application */
        pywars: AppServiceConfiguration;
        /** Skillable configuration, which is one of the lab integrations provided */
        skillable: AppServiceConfiguration;
        /** Files API, which the client requests student files from, directly */
        filesAPI: AppServiceConfiguration;
        /** 
         * The SANS Licensing Service (direct connection). For proxy requests,
         * use the `mylabsService` instead.
         */
        sansLicensingService: AppServiceConfiguration;
    };
    /** Endpoints that may be used by various functions or components */
    globalEndpoints: {
        /** The env-specific address for 'sans.org' */
        sansURL: string;
    };
    /** Feature integrations */
    runtimeEnv: RuntimeEnvironment;
}

/**
 * Create an AppConfiguration object.
 *
 * @example
 * ```ts
 * import AppConfig from './config/appConfig';
 * const config = AppConfig();
 * ```
 */
export default (
    /** The full value of `process.env`. */
    env?: Partial<NodeJS.ProcessEnv>
): AppConfiguration => {
    try {
        if (!env?.REACT_APP_RUNTIME_ENV && !process.env.REACT_APP_RUNTIME_ENV) {
            throw new Error('No runtime environment found');
        }
        const environment =
            (env?.REACT_APP_RUNTIME_ENV as RuntimeEnvironment) ||
            (process.env.REACT_APP_RUNTIME_ENV as RuntimeEnvironment);

        return {
            version: pkg.version,
            integrations: {
                cloudProviders: {
                    providers: {
                        default:
                            config.integrations.cloudProviders.providers
                                .default,
                    },
                },
            },
            globalEndpoints: {
                sansURL:
                    config.globalEndpoints.sansURL[
                        environment as keyof typeof config.globalEndpoints.sansURL
                    ],
            },
            services: {
                mylabsService: {
                    baseUrl:
                        config.services.myLabsService.baseUrl[
                            environment as keyof typeof config.services.myLabsService.baseUrl
                        ],
                    endpoints: {},
                },
                sansLicensingService: {
                    baseUrl:
                        config.services.sansLicensingService.baseUrl[
                            environment as keyof typeof config.services.sansLicensingService.baseUrl
                        ],
                    endpoints: {},
                },
                pywars: {
                    baseUrl:
                        config.services.pywars.baseUrl[
                            environment as keyof typeof config.services.pywars.baseUrl
                        ],
                    endpoints: {},
                },
                skillable: {
                    baseUrl:
                        config.services.skillable.baseUrl[
                            environment as keyof typeof config.services.skillable.baseUrl
                        ],
                    endpoints: {},
                },
                filesAPI: {
                    baseUrl:
                        config.services.filesAPI.baseUrl[
                            environment as keyof typeof config.services.filesAPI.baseUrl
                        ],
                    endpoints: {},
                },
            },
            runtimeEnv: environment,
        };
    } catch (e) {
        console.log(e);
        throw e;
    }
};
